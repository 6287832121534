import { AffiliatesEnum } from '../app/core/enums/affiliates.enum';
import { EnvironmentInterface } from './environment.interface';

/**
 * To add a new affiliate do the following:
 * 1. Adjust the AffiliatesEnum
 * 2. Add the informations in the affiliate-informations.json
 * 3. Adjust (Copy-Past-Adjust) the code that uses the affiliate-informations.json
 */

export const environment: EnvironmentInterface = {
  production: true,
  basePath: '/assets/',
  serverUrl: 'http://localhost:4201',
  allowedAffiliates: [AffiliatesEnum.OTTO_DE],
  shelfId: 'Q1cRtlw4haSlnQKVigdP',
  appVersion: '1.3.0',
  itemLimitPerLoad: 30,
  itemImageBaseSizes: 2000,
  mobilWidthBreakpoint: 768,

  // Firebase
  firebase: {
    apiKey: 'AIzaSyAt7wqI51posSjYql2N_16Am-ftApbmuvQ',
    authDomain: 'shelfdesigner-bbbcb.firebaseapp.com',
    databaseURL: 'https://shelfdesigner-bbbcb.firebaseio.com',
    projectId: 'shelfdesigner-bbbcb',
    storageBucket: 'shelfdesigner-bbbcb.appspot.com',
    messagingSenderId: '468652649634',
    appId: '1:468652649634:web:a15b662a63bb32a656fbfb',
    measurementId: 'G-WS2WXCCBS2',
  },

  // Firebase Development
  firebaseDev: {
    apiKey: 'AIzaSyCvUN-vDV76XFNoGGOfnSsZ3QMCsYBKKtA',
    authDomain: 'shelfy-dev-1b6a2.firebaseapp.com',
    projectId: 'shelfy-dev-1b6a2',
    storageBucket: 'shelfy-dev-1b6a2.appspot.com',
    messagingSenderId: '50500809737',
    appId: '1:50500809737:web:7f915e2b5dfba8830db4d8',
  },
};
